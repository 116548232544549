import styles from "./Footer.module.css";

import x from "../images/x.svg";
import yt from "../images/youtube.svg";
import fb from "../images/facebook.svg";
import wa from "../images/whatsapp.svg";
import tt from "../images/tiktok.svg";

function Footer() {
  return (
    <div className={styles.container}>
      {/* <div className={styles.blocks}>
        <div className={styles.block}>
          <p className={styles.head2}>Dirección</p>
          <p>Av. Villagran #32, Tangamandapio</p>
          <p className={styles.head2}>Teléfono</p>
          <p>+515555555555</p>
          <p className={styles.head2}>Redes sociales</p>
          <p>
            <img src={x} alt="X" />
            <img src={yt} alt="YouTube" />
            <img src={fb} alt="Facebook" />
            <img src={wa} alt="WhatsApp" />
            <img src={tt} alt="TikTok"/>
          </p>
        </div>
        <div className={styles.block}>
          <p className={styles.head1}>PRIVACIDAD Y SEGURIDAD</p>
          <p>Terminos y condiciones</p>
          <p>Politica de cookies</p>
        </div>
      </div> */}
      <p>Copyright © 2024 NeoCryptile</p>
    </div>
  );
}

export default Footer;
