import styles from "./About.module.css";

import Mision from "./Mision";
import Vision from "./Vision";
import WhoWeAre from "./WhoWeAre";

function About() {
    return <div className={styles.container}>
        <WhoWeAre />
        <Mision />
        <Vision />
    </div>
}

export default About;