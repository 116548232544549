import styles from "./WhoWeAre.module.css";

import image from "../images/contacto.jfif";

function Contact() {
  return (
    <div className={`${styles.container} ${styles.only_one} ${styles.full_h}`}>
      <div className={styles.header}>Contacto</div>
      <div className={`${styles.content} ${styles.reverse}`}>
        <div className={styles.image}>
          <img src={image} />
        </div>
        <div className={styles.form}>
          <form action="mailto:contatc@neocryptile.com" method="POST">
            <label for="name">Nombre:</label>
            <input type="text" id="name" name="user_name" />
            <label for="mail">Correo electrónico:</label>
            <input type="email" id="mail" name="user_mail" />
            <label for="msg">Mensaje:</label>
            <textarea id="msg" name="user_message"></textarea>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
