import styles from "./Home.module.css";

import video from "../images/bites.mp4";
import Logo from "../images/logo.png";
import Background from "./Background";
import Background1 from "./Background1";
import CardsBlock from "./CardsBlock";

function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.cover}>
        <Background />
        {/* <div className={styles.video_container}> */}
        {/* <video src={video} autoPlay loop muted /> */}
        {/* <Background1 /> */}
        {/* </div> */}
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={Logo} />
          </div>
          <div className={styles.text}>Aprender hoy, <br/> proteger mañana</div>
        </div>
      </div>
      <CardsBlock />
    </div>
  );
}

export default Home;
