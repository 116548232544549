import Card from "./Card";

import styles from "./CardsBlock.module.css";

function CardsBlock() {
  const data = [
    "El 90% de las brechas de seguridad comienzan con un ataque de phishing.",
    "En 2022, se estimó que el costo total de los daños por ransomware alcanzó los $20 mil millones de dólares.",
    "La pandemia impulsó un aumento en el trabajo remoto, lo que llevó a un incremento del 600% en ciberataques relacionados con el trabajo remoto.",
    "En 2021, México fue el país con el mayor número de ciberataques en América Latina, con más de 9,000 millones de intentos de ataque, según Fortinet.",
    "México ocupa el segundo lugar en América Latina en términos de ataques de ransomware.",
    "El cibercrimen le costó a México alrededor de $8 mil millones de dólares en 2020, según la Organización de los Estados Americanos (OEA).",
  ];
  return (
    <div className={styles.container}>
      {data.map((cardData, index) => (
        <Card key={index} data={cardData} />
      ))}
    </div>
  );
}

export default CardsBlock;
