import styles from "./Navigator.module.css";

import burguer from "../images/menu.svg";
import close from "../images/close.svg";
import logo from "../images/logo1.png";
import { useRef } from "react";

function Navigator(props) {
  const burguerButton = useRef();
  const setPage = (element, page) => {
    element.parentNode
      .querySelector(`li.${styles.active}`)
      ?.classList.remove(styles.active);
    element.classList.add(styles.active);
    props.setPage(page);
    burguerButton.current.classList.remove(styles.active);
    burguerButton.current.nextElementSibling.classList.remove(styles.show);
  };

  const showMenu = (element) => {
    element.classList.toggle(styles.active);
    element.nextElementSibling.classList.toggle(styles.show);
  }
  return (
    <>
      <img src={logo} className={styles.mobile_logo}/>
      <div className={styles.menu} onClick={e => showMenu(e.currentTarget)} ref={burguerButton}>
        <img src={burguer} />
        <img src={close} />
      </div>
      <div className={styles.container}>
        <ul>
          <li onClick={(e) => setPage(e.currentTarget, "home")}>
            <img src={logo} />
          </li>
          <li onClick={(e) => setPage(e.currentTarget, "home")}>Inicio</li>
          {/* <li onClick={(e) => setPage(e.currentTarget, "Más")}>Más</li> */}
          {/* <li onClick={(e) => setPage(e.currentTarget, "mision")}>Misión</li> */}
          {/* <li onClick={(e) => setPage(e.currentTarget, "vision")}>Visión</li> */}
          <li onClick={(e) => setPage(e.currentTarget, "about")}>
            Acerca de nosotros
          </li>
          <li onClick={(e) => setPage(e.currentTarget, "contact")}>Contacto</li>
        </ul>
      </div>
    </>
  );
}

export default Navigator;
