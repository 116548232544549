import styles from "./WhoWeAre.module.css";

import image from "../images/mision.jfif";

function Mision() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>Misión</div>
      <div className={`${styles.content} ${styles.reverse}`}>
        <div className={styles.image}>
          <img src={image} />
        </div>
        <div className={styles.text}>
          Fomentar el aprendizaje continuo y el desarrollo profesional en
          ciberseguridad, proporcionando a nuestros miembros acceso a recursos,
          formación y una comunidad de apoyo que les permita estar a la
          vanguardia de las tendencias y tecnologías emergentes en el campo de
          la ciberseguridad.
        </div>
      </div>
    </div>
  );
}

export default Mision;
