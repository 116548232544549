import styles from "./WhoWeAre.module.css";

import image from "../images/vision.jfif";

function Vision() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>Visión</div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img src={image} />
        </div>
        <div className={styles.text}>
          Ser una comunidad líder de estudiantes en ciberseguridad reconocida
          por su excelencia en el aprendizaje continuo, su capacidad para
          adaptarse a los desafíos tecnológicos emergentes y su contribución
          significativa a la protección del ciberespacio.
        </div>
      </div>
    </div>
  );
}

export default Vision;
