import styles from "./WhoWeAre.module.css";

import image from "../images/about.jfif";

function WhoWeAre() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>¿Quienes somos?</div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img src={image} />
        </div>
        <div className={styles.text}>
          Somos un grupo de apasionados por la ciberseguridad, todos ingresados
          del plan de becarios en Seguridad Informática del UNAM-CERT. Desde el
          año 2019, hemos participado activamente en varios concursos como el
          HackMex y el HackDef, siempre clasificando a las finales. Nuestra
          dedicación y compromiso con la ciberseguridad nos impulsa a
          mantenernos en constante capacitación, asegurando que estemos siempre
          actualizados y competitivos en un campo en constante evolución.
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
