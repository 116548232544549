import styles from "./Background.module.css";

function Background() {
  return (
    <div className={styles.container}>
      {Array.from({ length: 650 }).map((e, i) => (
        <p key={i} className={`${Math.random() > 0.85 ? styles.red : styles.blue }`}>
          {Math.round(Math.random())}
        </p>
      ))}
    </div>
  );
}

export default Background;
