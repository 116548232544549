import { useState } from 'react';

import styles from './App.module.css';

import Home from './Components/Home';
import Navigator from './Components/Navigator';
import WhoWeAre from './Components/WhoWeAre';
import Mision from './Components/Mision';
import Vision from './Components/Vision';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import About from './Components/About';

function App() {
  const [pageComponent, setPageComponent] = useState("home");
  const components = {
    "home": <Home />,
    "navigator": <Navigator />,
    "about": <About />,
    "contact": <Contact />,
    // "mision": <Mision />,
    // "vision": <Vision />,
    // "about": <WhoWeAre />,
  }

  const setPage = (page) => {
    setPageComponent(page);
  }

  return <div className={styles.wrapper}>
    <Navigator page={pageComponent} setPage={setPage} />
    {components[pageComponent]}
    <Footer />
  </div>
  
}

export default App;
